import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DaterangepickerFnsComponent } from './daterangepicker-fns.component';
import { DaterangepickerFnsDirective } from './daterangepicker-fns.directive';
import { LocaleFnsConfig, LOCALE_CONFIG } from './daterangepicker.config';
import { I18NextModule } from 'angular-i18next';
import { AnyUiModule } from '@anymindgroup/any-ui-angular';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DaterangepickerFnsComponent, DaterangepickerFnsDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, I18NextModule, AnyUiModule, MatIconModule],
  providers: [],
  exports: [DaterangepickerFnsComponent, DaterangepickerFnsDirective],
})
export class NgxDaterangepickerFns {
  constructor() {}
  static forRoot(config: LocaleFnsConfig = {}): ModuleWithProviders<NgxDaterangepickerFns> {
    return {
      ngModule: NgxDaterangepickerFns,
      providers: [{ provide: LOCALE_CONFIG, useValue: config }],
    };
  }
}
