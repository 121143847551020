<div
  #pickerContainer
  [ngClass]="{
    ltr: locale.direction === 'ltr',
    rtl: locale.direction === 'rtl',
    shown: isShown || inline,
    hidden: !isShown && !inline,
    inline: inline,
    double: !singleDatePicker && showCalInRanges,
    'show-ranges': rangesArray.length,
    'single-date-picker': singleDatePicker
  }"
  [class]="'drops-' + drops + '-' + opens + ' ' + classCustom"
  class="md-drppicker-fns"
>
  <div class="container">
    @if (rangesArray.length > 0) {
      <div class="ranges">
        <ul>
          @for (range of rangesArray; track range) {
            <li>
              <button
                [disabled]="disableRange(range)"
                [ngClass]="{ active: range === chosenRange }"
                (click)="clickRange($event, range)"
                class="range-btn"
                anyButton
                themeColor="primary"
              >
                {{ 'DATE_RANGE.' + range.split(' ').join('_').toUpperCase() | i18next: { defaultValue: '' } }}
              </button>
            </li>
          }
        </ul>
      </div>
    }
    @if (showCalInRanges) {
      <div [ngClass]="{ right: singleDatePicker, left: !singleDatePicker }" class="calendar">
        <div class="calendar-table">
          @if (calendarVariables) {
            <table class="table-condensed">
              <thead>
                <tr>
                  @if (showWeekNumbers || showISOWeekNumbers) {
                    <th></th>
                  }
                  @if (
                    !calendarVariables.left.minDate ||
                    (isBefore(calendarVariables.left.minDate, calendarVariables.left.calendar.firstDay) && (!linkedCalendars || true))
                  ) {
                    <th (click)="clickPrev(sideEnum.left)" class="prev available">
                      <mat-icon>chevron_left</mat-icon>
                    </th>
                  }
                  @if (
                    !(
                      !calendarVariables.left.minDate ||
                      (isBefore(calendarVariables.left.minDate, calendarVariables.left.calendar.firstDay) && (!linkedCalendars || true))
                    )
                  ) {
                    <th></th>
                  }
                  <th class="month drp-animate" colspan="5">
                    <!-- NOTE: not show select dropdown month/year for now -->
                    @if (showDropdowns && calendarVariables.left.dropdowns) {
                      <!-- class="dropdowns" -->
                      <div>
                        <span class="calendar-header">
                          {{ locale.monthFullNames[fns.getMonth(calendarVariables?.left?.calendar[1][1])] }}
                        </span>
                        @if (false) {
                          <select (change)="monthChanged($event, sideEnum.left)" class="monthselect">
                            @for (m of calendarVariables.left.dropdowns.monthArrays; track m) {
                              <option
                                [disabled]="
                                  (calendarVariables.left.dropdowns.inMinYear && m < fns.getMonth(calendarVariables.left.minDate)) ||
                                  (calendarVariables.left.dropdowns.inMaxYear && m > fns.getMonth(calendarVariables.left.maxDate))
                                "
                                [value]="m"
                                [selected]="calendarVariables.left.dropdowns.currentMonth === m"
                              >
                                {{ locale.monthNames[m] }}
                              </option>
                            }
                          </select>
                        }
                      </div>
                      <!-- class="dropdowns" -->
                      <div>
                        <span class="calendar-header">
                          {{ fns.format(calendarVariables?.left?.calendar[1][1], ' yyyy')
                          }}{{ 'DATE_TIME_PICKER.YEAR_SUFFIX' | i18next: { defaultValue: '' } }}
                        </span>
                        @if (false) {
                          <select (change)="yearChanged($event, sideEnum.left)" class="yearselect">
                            @for (y of calendarVariables.left.dropdowns.yearArrays; track y) {
                              <option [selected]="y === calendarVariables.left.dropdowns.currentYear">
                                {{ y }}{{ 'DATE_TIME_PICKER.YEAR_SUFFIX' | i18next: { defaultValue: '' } }}
                              </option>
                            }
                          </select>
                        }
                      </div>
                    }
                    @if (!showDropdowns || !calendarVariables.left.dropdowns) {
                      {{ locale.monthNames[fns.getMonth(calendarVariables?.left?.calendar[1][1])] }}
                      {{ fns.format(calendarVariables?.left?.calendar[1][1], ' yyyy') }}
                    }
                  </th>
                  @if (
                    (!calendarVariables.left.maxDate ||
                      fns.isAfter(calendarVariables.left.maxDate, calendarVariables.left.calendar.lastDay)) &&
                    (!linkedCalendars || singleDatePicker)
                  ) {
                    <th (click)="clickNext(sideEnum.left)" class="next available">
                      <mat-icon>chevron_right</mat-icon>
                    </th>
                  }
                  @if (
                    !(
                      (!calendarVariables.left.maxDate ||
                        fns.isAfter(calendarVariables.left.maxDate, calendarVariables.left.calendar.lastDay)) &&
                      (!linkedCalendars || singleDatePicker)
                    )
                  ) {
                    <th></th>
                  }
                </tr>
                <tr class="week-days">
                  @if (showWeekNumbers || showISOWeekNumbers) {
                    <th class="week">
                      <span>{{ locale.weekLabel }}</span>
                    </th>
                  }
                  @for (dayofweek of locale.daysOfWeek; track dayofweek) {
                    <th>
                      <span>{{ dayofweek }}</span>
                    </th>
                  }
                </tr>
              </thead>
              <tbody #leftCalendar class="drp-animate">
                @for (row of calendarVariables.left.calRows; track row) {
                  <tr [class]="calendarVariables.left.classes[row].classList">
                    <!-- add week number -->
                    @if (showWeekNumbers) {
                      <td class="week">
                        <span>{{ fns.getWeek(calendarVariables.left.calendar[row][0]) }}</span>
                      </td>
                    }
                    @if (showISOWeekNumbers) {
                      <td class="week">
                        <span>{{ fns.getISOWeek(calendarVariables.left.calendar[row][0]) }}</span>
                      </td>
                    }
                    <!-- cal -->
                    @for (col of calendarVariables.left.calCols; track col) {
                      <td
                        [class]="calendarVariables.left.classes[row][col]"
                        [class.single-date-selected]="isSingleDateSelected(sideEnum.left, row, col)"
                        (click)="clickDate($event, sideEnum.left, row, col)"
                      >
                        <span>{{ fns.getDate(calendarVariables.left.calendar[row][col]) }}</span>
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          }
        </div>
        @if (timePicker) {
          <div class="calendar-time">
            <div class="select">
              <select
                [(ngModel)]="timepickerVariables.left.selectedHour"
                [disabled]="!endDate"
                (ngModelChange)="timeChanged($event, sideEnum.left, 'selectedHour')"
                class="hourselect select-item"
              >
                @for (i of timepickerVariables.left.hours; track i) {
                  <option [value]="i" [disabled]="timepickerVariables.left.disabledHours.indexOf(i) > -1">
                    {{ i }}
                  </option>
                }
              </select>
            </div>
            <div class="select">
              <select
                [(ngModel)]="timepickerVariables.left.selectedMinute"
                [disabled]="!endDate"
                (ngModelChange)="timeChanged($event, sideEnum.left, 'selectedMinute')"
                class="select-item minuteselect"
              >
                @for (i of timepickerVariables.left.minutes; track i; let index = $index) {
                  <option [value]="i" [disabled]="timepickerVariables.left.disabledMinutes.indexOf(i) > -1">
                    {{ timepickerVariables.left.minutesLabel[index] }}
                  </option>
                }
              </select>
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
            </div>
            <div class="select">
              @if (timePickerSeconds) {
                <select
                  [(ngModel)]="timepickerVariables.left.selectedSecond"
                  [disabled]="!endDate"
                  (ngModelChange)="timeChanged($event, sideEnum.left, 'selectedSecond')"
                  class="select-item secondselect"
                >
                  @for (i of timepickerVariables.left.seconds; track i; let index = $index) {
                    <option [value]="i" [disabled]="timepickerVariables.left.disabledSeconds.indexOf(i) > -1">
                      {{ timepickerVariables.left.secondsLabel[index] }}
                    </option>
                  }
                </select>
              }
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
            </div>
            <div class="select">
              @if (!timePicker24Hour) {
                <select
                  [(ngModel)]="timepickerVariables.left.ampmModel"
                  (ngModelChange)="timeChanged($event, sideEnum.left, 'ampmModel')"
                  class="select-item ampmselect"
                >
                  <option [disabled]="timepickerVariables.left.amDisabled" value="AM">AM</option>
                  <option [disabled]="timepickerVariables.left.pmDisabled" value="PM">PM</option>
                </select>
              }
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
            </div>
          </div>
        }
      </div>
    }
    @if (showCalInRanges && !singleDatePicker) {
      <div class="calendar right">
        <div class="calendar-table">
          @if (calendarVariables) {
            <table class="table-condensed">
              <thead>
                <tr>
                  @if (showWeekNumbers || showISOWeekNumbers) {
                    <th></th>
                  }
                  @if (
                    (!calendarVariables.right.minDate ||
                      isBefore(calendarVariables.right.minDate, calendarVariables.right.calendar.firstDay)) &&
                    !linkedCalendars
                  ) {
                    <th (click)="clickPrev(sideEnum.right)" class="prev available">
                      <mat-icon>chevron_left</mat-icon>
                    </th>
                  }
                  @if (
                    !(
                      (!calendarVariables.right.minDate ||
                        isBefore(calendarVariables.right.minDate, calendarVariables.right.calendar.firstDay)) &&
                      !linkedCalendars
                    )
                  ) {
                    <th></th>
                  }
                  <th class="month" colspan="5">
                    @if (showDropdowns && calendarVariables.right.dropdowns) {
                      <!-- class="dropdowns" -->
                      <div>
                        <span class="calendar-header">
                          {{ locale.monthFullNames[fns.getMonth(calendarVariables?.right?.calendar[1][1])] }}
                        </span>
                        @if (false) {
                          <select (change)="monthChanged($event, sideEnum.right)" class="monthselect">
                            @for (m of calendarVariables.right.dropdowns.monthArrays; track m) {
                              <option
                                [disabled]="
                                  (calendarVariables.right.dropdowns.inMinYear && m < fns.getMonth(calendarVariables.right.minDate)) ||
                                  (calendarVariables.right.dropdowns.inMaxYear && m > fns.getMonth(calendarVariables.right.maxDate))
                                "
                                [value]="m"
                                [selected]="calendarVariables.right.dropdowns.currentMonth === m"
                              >
                                {{ locale.monthNames[m] }}
                              </option>
                            }
                          </select>
                        }
                      </div>
                      <!-- class="dropdowns" -->
                      <div>
                        <span class="calendar-header">
                          {{ fns.format(calendarVariables?.right?.calendar[1][1], ' yyyy')
                          }}{{ 'DATE_TIME_PICKER.YEAR_SUFFIX' | i18next: { defaultValue: '' } }}
                        </span>
                        @if (false) {
                          <select (change)="yearChanged($event, sideEnum.right)" class="yearselect">
                            @for (y of calendarVariables.right.dropdowns.yearArrays; track y) {
                              <option [selected]="y === calendarVariables.right.dropdowns.currentYear">
                                {{ y }}{{ 'DATE_TIME_PICKER.YEAR_SUFFIX' | i18next: { defaultValue: '' } }}
                              </option>
                            }
                          </select>
                        }
                      </div>
                    }
                    @if (!showDropdowns || !calendarVariables.right.dropdowns) {
                      {{ locale.monthNames[fns.getMonth(calendarVariables?.right?.calendar[1][1])] }}
                      {{ fns.format(calendarVariables?.right?.calendar[1][1], ' yyyy') }}
                    }
                  </th>
                  @if (
                    !calendarVariables.right.maxDate ||
                    (fns.isAfter(calendarVariables.right.maxDate, calendarVariables.right.calendar.lastDay) &&
                      (!linkedCalendars || singleDatePicker || true))
                  ) {
                    <th (click)="clickNext(sideEnum.right)" class="next available">
                      <mat-icon>chevron_right</mat-icon>
                    </th>
                  }
                  @if (
                    !(
                      !calendarVariables.right.maxDate ||
                      (fns.isAfter(calendarVariables.right.maxDate, calendarVariables.right.calendar.lastDay) &&
                        (!linkedCalendars || singleDatePicker || true))
                    )
                  ) {
                    <th></th>
                  }
                </tr>
                <tr>
                  @if (showWeekNumbers || showISOWeekNumbers) {
                    <th class="week">
                      <span>{{ locale.weekLabel }}</span>
                    </th>
                  }
                  @for (dayofweek of locale.daysOfWeek; track dayofweek) {
                    <th>
                      <span>{{ dayofweek }}</span>
                    </th>
                  }
                </tr>
              </thead>
              <tbody #rightCalendar>
                @for (row of calendarVariables.right.calRows; track row) {
                  <tr [class]="calendarVariables.right.classes[row].classList">
                    @if (showWeekNumbers) {
                      <td class="week">
                        <span>{{ fns.getWeek(calendarVariables.right.calendar[row][0]) }}</span>
                      </td>
                    }
                    @if (showISOWeekNumbers) {
                      <td class="week">
                        <span>{{ fns.getISOWeek(calendarVariables.right.calendar[row][0]) }}</span>
                      </td>
                    }
                    @for (col of calendarVariables.right.calCols; track col) {
                      <td
                        [class]="calendarVariables.right.classes[row][col]"
                        [class.single-date-selected]="isSingleDateSelected(sideEnum.right, row, col)"
                        (click)="clickDate($event, sideEnum.right, row, col)"
                      >
                        <span>{{ fns.getDate(calendarVariables.right.calendar[row][col]) }}</span>
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          }
        </div>
        @if (timePicker) {
          <div class="calendar-time">
            <div class="select">
              <select
                [(ngModel)]="timepickerVariables.right.selectedHour"
                [disabled]="!endDate"
                (ngModelChange)="timeChanged($event, sideEnum.right, 'selectedHour')"
                class="select-item hourselect"
              >
                @for (i of timepickerVariables.right.hours; track i) {
                  <option [value]="i" [disabled]="timepickerVariables.right.disabledHours.indexOf(i) > -1">
                    {{ i }}
                  </option>
                }
              </select>
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
            </div>
            <div class="select">
              <select
                [(ngModel)]="timepickerVariables.right.selectedMinute"
                [disabled]="!endDate"
                (ngModelChange)="timeChanged($event, sideEnum.right, 'selectedMinute')"
                class="select-item minuteselect"
              >
                @for (i of timepickerVariables.right.minutes; track i; let index = $index) {
                  <option [value]="i" [disabled]="timepickerVariables.right.disabledMinutes.indexOf(i) > -1">
                    {{ timepickerVariables.right.minutesLabel[index] }}
                  </option>
                }
              </select>
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
            </div>
            <div class="select">
              @if (timePickerSeconds) {
                <select
                  [(ngModel)]="timepickerVariables.right.selectedSecond"
                  [disabled]="!endDate"
                  (ngModelChange)="timeChanged($event, sideEnum.right, 'selectedSecond')"
                  class="select-item secondselect"
                >
                  @for (i of timepickerVariables.right.seconds; track i; let index = $index) {
                    <option [value]="i" [disabled]="timepickerVariables.right.disabledSeconds.indexOf(i) > -1">
                      {{ timepickerVariables.right.secondsLabel[index] }}
                    </option>
                  }
                </select>
              }
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
            </div>
            <div class="select">
              @if (!timePicker24Hour) {
                <select
                  [(ngModel)]="timepickerVariables.right.ampmModel"
                  (ngModelChange)="timeChanged($event, sideEnum.right, 'ampmModel')"
                  class="select-item ampmselect"
                >
                  <option [disabled]="timepickerVariables.right.amDisabled" value="AM">AM</option>
                  <option [disabled]="timepickerVariables.right.pmDisabled" value="PM">PM</option>
                </select>
              }
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
            </div>
          </div>
        }
      </div>
    }
  </div>
  @if (!autoApply && (!rangesArray.length || (showCalInRanges && !singleDatePicker))) {
    <div class="buttons">
      @if (showClearButton) {
        <button [outlined]="true" [themeColor]="'default'" (click)="clear()" class="mx-2" anyButton title="clear the date">
          {{ locale.clearLabel }}
        </button>
      }
      <div class="date-range">
        {{ dateRangeTextPreview }}
      </div>
      @if (showCancel) {
        <button [outlined]="true" (click)="clickCancel($event)" class="cancel-btn" anyButton themeColor="default">
          {{ locale.cancelLabel }}
        </button>
      }
      <button [disabled]="applyBtn.disabled" (click)="clickApply($event)" anyButton themeColor="primary">
        {{ locale.applyLabel }}
      </button>
    </div>
  }
</div>
