import { InjectionToken } from '@angular/core';

export const LOCALE_CONFIG = new InjectionToken<LocaleFnsConfig>('daterangepicker.config');
/**
 *  LocaleConfig Interface
 */
export interface LocaleFnsConfig {
  chooseLabel?: string;
  direction?: string;
  separator?: string;
  weekLabel?: string;
  applyLabel?: string;
  cancelLabel?: string;
  clearLabel?: string;
  customRangeLabel?: string;
  daysOfWeek?: string[];
  monthNames?: string[];
  monthFullNames?: string[];
  firstDay?: number;
  format?: string;
}
/**
 *  DefaultLocaleConfig
 */
export const DefaultLocaleConfig: LocaleFnsConfig = {
  direction: 'ltr',
  separator: ' - ',
  weekLabel: 'W',
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  clearLabel: 'Clear',
  customRangeLabel: 'Custom',
  daysOfWeek: [], // TODO: export function for week days and month names default for 'enUS'.
  monthNames: [],
  monthFullNames: [],
  firstDay: 1,
};

export interface DateRange {
  label: string;
  dates: DatesFns;
}

export interface DatesFns {
  start: Date;
  end: Date;
}
